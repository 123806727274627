const DATA = [
//  {
//    id: "38",
//    in: true,
//    title: "Condominio Valle de los Olivos",
//    description: "Hermosas Parcelas de Agrado de 5.000 m2, ideal para quienes buscan vivir rodeado de la majestuosidad de la naturaleza y en un entorno seguro dentro de la Sexta Región.",
//    ubication: "Pelequén",
//    mainImage: require("./images/p1/1.jpg"),
//  }, 
  {
    id: "55",
    in: true,
    title: "Condominio Trilco",
    description: "Vive Condominio Trilco, espectaculares sitios de 5.000 m2, a minutos de Lago Vichuquén, Llico y Lipimavida.",
    ubication: "Vichuquén",
    mainImage: require("./images/p2/1.jpg"),
  },  
  {
    id: "73",
    in: true,
    title: "Centro Comercial Machalí",
    description: "Para emprendedores o negocios consolidados te presentamos estos locales comerciales con amplios estacionamientos y disponibilidad inmediata: valores de arriendo desde $190.000! Con variedad de tamaños para que desarrolles tu idea en los m2 que necesites.",
    ubication: "Machalí",
    mainImage: require("./images/p3/1.jpg"),
  },  
  {
    id: "78",
    in: true,
    title: "Barrio Industrial KM90",
    description: "Barrio Industrial KM90 en un reconocido polo industrial para Rancagua, donde convergen distintos rubros y actores relevantes para la economía regional.",
    ubication: "Rancagua",
    mainImage: require("./images/p4/1.jpg"),
  },  
  {
    id: "62",
    in: true,
    title: "Loteo Eastman",
    description: "Ahora 2º Etapa venta de sitios de 500 a 700 m2. 1º Etapa Vendida. Urbanización completa  (luz eléctrica, agua potable y alcantarillado), portón eléctrico, completamente planos.",
    ubication: "Olmué",
    mainImage: require("./images/p5/1.jpg"),
  },  
  {
    id: "67",
    in: true,
    title: "Bahía Pullihué - Chiloé",
    description: "nigualable loteo en Chiloé, terrenos desde 5.000m2 en espectacular orilla de mar ubicada en camino que une Ancud con el famoso Faro Corona. Lugar virgen, espectaculares vistas y amaneceres, surf, trekking, pesca, diversas playas y paseos a minutos del lugar. Vida de lago, con las bondades del pacífico y excelente conectividad.",
    ubication: "Ancud",
    mainImage: require("./images/p6/1.jpg"),
  },  
  {
    id: "75",
    in: true,
    title: "Llafenco Park",
    description: "Sitios rodeado de hermosos bosques nativos, volcanes y ríos que dan a la zona un paisaje único, a pasos de Pucón en un entorno acogedor y seguro para disfrutar del espectacular paisaje.",
    ubication: "Pucón",
    mainImage: require("./images/p7/1.jpg"),
  },  
  {
    id: "76",
    in: true,
    title: "Departamentos en Santiago para inversión",
    description: "No quedes fuera de la tendencia de inversión inmobiliaria, asegura tu jubilación y cuida tus fondos previsionales de la mejor manera.",
    ubication: "San Miguel",
    mainImage: require("./images/p8/1.jpg"),
  },  
  {
    id: "77",
    in: true,
    title: "Loteo Playa Sirena - Pichilemu",
    description: "Espectaculares lotes de 5.000m2 ubicados al sur de Cahuil frente a playa La Sirena.",
    ubication: "Pichilemu",
    mainImage: require("./images/p9/1.jpg"),
  },  
//  {
//    id: "71",
//    in: true,
//    title: "Jardines de Montemar III",
//    description: "Exclusivos Departamentos, la mejor oportunidad para vivir en Bosques de Montemar. Con un excelente entorno tranquilo y rodeado de la naturaleza. cercano a comercio, variada gastronomía en el borde costero, colegios, centros médicos.",
//    ubication: "Concón",
//    mainImage: require("./images/p10/1.jpg"),
//  },  
//  {
//    id: "72",
//    in: true,
//    title: "Costa Montemar II",
//    description: "Costa Montemar II está ubicado en un sector tranquilo y seguro, donde podrás disfrutar realmente un descanso desde el primer día.",
//    ubication: "Concón",
//    mainImage: require("./images/p11/1.jpg"),
//  },  
//  {
//    id: "61",
//    in: false,
//    title: "Natiivo Miami",
//    description: "Bienvenidos a la nueva manera de tener una propiedad en Miami. Te presentamos Natiivo, proyecto de renta residencial ubicado cercano al centro de Miami a pasos del American Airlines Arena, próximo a puerto de Miami, y a sector financiero Brickell.",
//    ubication: "EEUU - Miami, Florida",
//    mainImage: require("./images/p12/1.jpg"),
//  },  
//  {
//    id: "74",
//    in: false,
//    title: "Zenodro Hidden Forest - Orlando",
//    description: "Invertir y disfrutar no son conceptos excluyentes. Te presentamos Zenodro Hidden Forest, un espectacular condominio en las afueras de Orlando cercano a los principales parques y atracciones turísticas.",
//    ubication: "EEUU - Orlando, Florida",
//    mainImage: require("./images/p13/1.jpg"),
//  },
                         
]

export default DATA;
