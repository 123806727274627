import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';
import { Section, Button } from '../../styled-components';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { EnvironmentOutlined } from '@ant-design/icons';

import DATA from './DATA';

const ProjectCont = styled.div`
  width: 100%;
  height: 500px;
  background-image: url("${props => props.src}");
  background-position: center;
  background-size: cover;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`
const ProjectInnerCont = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, .8), rgba(0, 0, 0, .8));
  padding: 1rem;
  display: none;
  user-select: none;
  ${ProjectCont}:hover & {
    display: block;
  }
`
const ProjectTitle = styled.p`
  color: #fff;
  text-shadow: 0 0 5px black;
  font-size: 1.3rem;
`
const ProjectDescription = styled.p`

`
const ProjectUbication = styled.p`
  display: flex;
  align-items: center;
  span{
    margin-right: .25rem;
  }
`
const Project = ({ title, description, ubication, mainImage })=> (
  <ProjectCont src={mainImage}>
    <ProjectTitle>
      {title}
    </ProjectTitle>
    <ProjectInnerCont>
      <ProjectUbication>
        <EnvironmentOutlined />
        {ubication}
      </ProjectUbication>
      <ProjectTitle>
        {title}
      </ProjectTitle>
      <ProjectDescription>
        {description}
      </ProjectDescription>      
      <AniLink paintDrip hex="#007836" to="/contact" duration={.5}>
        <Button primary>
          Saber más
        </Button>
      </AniLink>
    </ProjectInnerCont>
  </ProjectCont>
)
export default ()=> {

  return(
    <Section>
      <Container>
        <Row>
          {
            DATA.filter(project => project.in === true).map(project => (
              <Col xs={12} md={4} key={project.id}>
                <Project {...project} />
              </Col>
            ))
          }
        </Row>
      </Container>
    </Section>
  )
}